@import "./assets/fonts/openSans/index.scss";

body {
  background-color: #f2f2f2;
  font-family: "OpenSans", serif, sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.fb-color {
  background-color: #3b5998;
}

.fb-color:hover {
  background-color: #3b5998;
  opacity: 0.9;
}

.link {
  color: #007aff;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.disabled {
    color: #444;
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  background: #d2d2d2;
  border: 3px solid #f4f4f4;
  border-right: 3px solid #f4f4f4;
  border-radius: 25px;
  margin-left: 1px;
}
::-webkit-scrollbar-track {
  background: #f4f4f4;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.text-flex-inline {
  display: flex !important;
  flex-direction: row !important;
}

.leftSide {
  width: auto;
}

.centerSide {
  flex: 1;
}

.rightSide {
  width: auto;
}

.btn {
  text-transform: initial;
}

.flexToEnd {
  justify-content: flex-end;
}

.hideElement {
  display: none !important;
}

.ReactModal__Content, .ReactModal__Overlay {
  z-index: 100 !important;
}
.tooltip {
  background: #000 !important;
}
.listItemsControl {
  position: relative;

  & .btn {
    position: relative;
    min-width: 38px;
    width: 38px;
    height: 38px;
  }

    & .listItems-item {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 11px;

      & .waiterButtonWrap {
        min-width: 24px;
        width: 24px;
        height: 24px;

        & .actionIcon {
          background: #007aff;
        }
      }
    }
  &.listItemsControlRtl{
    margin-left: auto !important;
  }
}
.listItemsControl .dropdownTriggerControl.btn{
  border-radius: 50px !important;
  &.dropdownTriggerControlActive{
    background: none !important;
    border: 1px solid #007aff !important;

    .actionIcon.more-dots{
      background: #007aff;
    }
  }

}

.dropdownControl {
  position: relative;

  & .btn {
    position: relative;
    min-width: 38px;
    width: 38px;
    height: 38px;
  }

  & .dropdown-list {
    position: absolute;
    top: 43px;
    right: 0;
    width: 152px;
    height: auto;
    opacity: 0;
    z-index: -100;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 16px;
    border-radius: 4px;
    gap: 8px;
    background: #ffffff;
    box-shadow: 0 2px 6px 0 #00000026;

    &.dropdownListRtl{
      right: auto;
      left: 0;
    }

    &.active {
      opacity: 1;
      z-index: 10;
    }
  }
}
.dropdownControl .dropdownTriggerControl.btn{
  border-radius: 50px !important;
  &.dropdownTriggerControlActive{
    background: none !important;
    border: 1px solid #007aff !important;

    .actionIcon.more-dots{
      background: #007aff;
    }
  }

}

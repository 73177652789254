@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    src: url("OpenSans-Regular.ttf") format("opentype");
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 300;
    src: url("OpenSans-Light.ttf") format("opentype");
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 600;
    src: url("OpenSans-Bold.ttf") format("opentype");
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    src: url("OpenSans-ExtraBold.ttf") format("opentype");
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 800;
    src: url("OpenSans-SemiBold.ttf") format("opentype");
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style:  italic;
    src: url("OpenSans-Italic.ttf") format("opentype");
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style:  italic;
    font-weight: 300;
    src: url("OpenSans-LightItalic.ttf") format("opentype");
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style:  italic;
    font-weight: 500;
    src: url("OpenSans-MediumItalic.ttf") format("opentype");
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style:  italic;
    font-weight: 600;
    src: url("OpenSans-BoldItalic.ttf") format("opentype");
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style:  italic;
    font-weight: 700;
    src: url("OpenSans-ExtraBoldItalic.ttf") format("opentype");
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-style:  italic;
    font-weight: 800;
    src: url("OpenSans-SemiBoldItalic.ttf") format("opentype");
  }
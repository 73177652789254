.chatCounter {
  display: flex;
  align-content: center;
}

.countUnreadMessages {
  background: #C0DEFF;
  color: #007AFF;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  p {
    margin: 0;
    line-height: 1;
  }
  &:last-child {
    margin-left: 4px;
  }

  .actionIcon {
    background: #007AFF;
    width: 16px;
    height: 16px;
    margin: 0 5px 0 0;
  }
}

.rtl {
  .countUnreadMessages {
    margin: 0;
    &:last-child {
      margin-right: 4px;
    }

    .actionIcon {
      margin: 0 0 0 5px;
    }
  }
}
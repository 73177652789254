#app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  text-align: center;  
}

#title {
  margin-top: 0;
  font-size: 1.5em;
}

#content {
  flex-grow: 2;
  max-height: calc(100vh - 50px);
  position: relative;

  &.hasNotes {
    max-height: calc(100vh - 85px);
    @media (max-width: 430px) {
      max-height: calc(100vh - 100px);
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.readonly {
  input {
    background: #f8f8f8;
  }
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.content {
  display: flex;
  height: 100%;
  margin-top: 0;
  overflow: hidden;
}

.pageContentContainer {
  display: flex;
  width: 100%;
}

.grayWrapper {
  background: #ebebeb;
  padding: 20px;
}

.divider {
  background-color: transparent !important;
  color: #007aff;
  height: auto;
  overflow: initial;
}

.inline {
  display: inline-block;
}

.bold {
  font-weight: 600;
}

.inlineProp {
  display: inline-block;
  margin: 0 0 0 7px;
}

.inlineFlex {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.inlineFlexRow {
  flex-direction: row !important;
}

.centered {
  text-align: center !important;
}

.btn {
  border-radius: 3px !important;
  box-shadow: none;
  font-size: 14px !important;
  letter-spacing: initial !important;
  outline: none !important;
  text-align: center;
  transition: none !important;
  min-width: 105px;
  padding: 0 9px !important;
}

.confirm {
  background: #007aff !important;
  border: none !important;
  color: #fff !important;
  cursor: pointer !important;

  &:hover {
    background: #389aff !important;
    box-shadow: none;
  }

  &.disabled {
    cursor: default !important;
    background: #e2e2e2 !important;
    color: #fff !important;
  }
}

.denied {
  background: #fff !important;
  border: 1px solid #389aff;
  color: #717171;
  cursor: pointer;

  &:hover {
    background: #fff !important;
    border-color: #389aff;
    box-shadow: none;
  }
}

.verticalScroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.changed {
  border: 1px solid rgb(76, 175, 80) !important;

  &:focus {
    border-bottom: 1px solid rgb(76, 175, 80) !important;
  }
}

.hasError {
  background: #ffe6e6 !important;
  border: 1px solid #de9f9f !important;
  color: #bf7a7a !important;
}

.control-action {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.action-description {
  color: #007aff;
  font-size: 0.8rem;
  font-weight: 800;
}

div {
  &.preloader {
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url("../assets/images/preloader_big.gif") no-repeat center
      rgba(100, 100, 100, 0.5);
  }

  &.loader {
    background: url("../assets/images/preloader.GIF") no-repeat center;
    height: 20px;
    width: 20px;
  }
}

.toast {
  background: #bd362f !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  min-width: 250px;
  opacity: 0.8 !important;
  padding: 10px 10px !important;
  transition: none !important;

  .Toastify__toast-body {
    text-align: start;
  }
  &.Toastify__toast--info {
    background: #fe7171 !important;
  }

  button {
    margin-left: 20px;

    &.disabled {
      background: #e2e2e2;
      color: #fff;
    }
  }
}

.dragPlaceholder {
  cursor: auto;
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: #bfbebe;
  opacity: 1;
  z-index: 2000;
}

.tooltipText {
  background: #fff;
  border: 1.5px solid #ddd;
  color: #444;
  font-size: 12px;
  left: 0;
  min-width: 100px;
  padding: 4px 0;
  position: absolute;
  right: -50%;
  text-align: center;
  top: -40px;
  visibility: hidden;
  z-index: 100;

  &::after {
    border-top: 7px solid #ddd;
    border-bottom: 7px solid transparent;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    height: 0;
    left: calc(50% - 4px);
    position: absolute;
    top: 100%;
    width: 0;
  }
}

.sectionItem {
  color: #475f7b;
  display: inline-block;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.4;
  overflow: hidden;
  padding-top: 10px;
  text-align: start;
  width: 50%;
}

.propItemLabel {
  color: #475f7b;
  font-size: 0.87rem;
  font-weight: 800;
  margin-bottom: 0.2rem;
}

.newOrderControlWrapper {
  background-color: #fcfcfc;
  border-bottom: 1px solid #dbdbdb;
  padding: 5px 10px;
  position: absolute;
  transform: translateY(0);
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  top: 40px;
  width: 100%;
  z-index: 1;

  &.hideControl {
    top: 0;
    transform: translateY(-100%);
  }
}

.overflowHiddenText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflowNextRowText {
  word-wrap: break-word
}

.orangeColor {
  color: #ff5b2e !important;
}

.blueColor {
  color: #007aff;
}

.colorItem {
  height: 25px;
  width: 25px;
}

.imageEmpty {
  background: url("../assets/images/icons/no_photo.png") no-repeat 50% 50% #b6b6b6;
  border-radius: 50%;
  height: 52px;
  width: 52px;
}

.noMarginTop {
  margin-top: 0 !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.defaultSelect {
  -webkit-user-select: initial !important; /* Safari */
  -moz-user-select: initial !important; /* Firefox */
  -ms-user-select: initial !important; /* IE10+/Edge */
  user-select: initial !important; /* Standard */

  span, div {
    -webkit-user-select: initial !important; /* Safari */
    -moz-user-select: initial !important; /* Firefox */
    -ms-user-select: initial !important; /* IE10+/Edge */
    user-select: initial !important; /* Standard */
  }
}

.withoutSelect {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  span, div {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
}

.approve-button-block {
  padding: 0 13px 0 5px;

  .approve-button {
    background: #007aff;
    border: 1px solid #007aff;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    height: 38px;
    line-height: 0;
    text-align: center;
    width: 56px;

    svg {
      fill: #fff;
      height: 27px;
      position: relative;
      top: 5px;
      width: 27px;
    }

    &:hover {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    }
  }
}

.rtl {
  .inlineProp {
    margin: 0 0 0 7px;
  }

  .approve-button-block {
    .approve-button {
      svg {
        transform: scale(-1, 1);
      }
    }
  }
}

.bounce{
  animation-name: bounce;
  -webkit-animation-name: bounce;

  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
}

@keyframes bounce {
  0% {
      transform: translateY(0%) scaleY(0.6);
  }
  60%{
      transform: translateY(-100%) scaleY(1.1);
  }
  70%{
      transform: translateY(0%) scaleY(0.95) scaleX(1.05);
  }
  80%{
      transform: translateY(0%) scaleY(1.05) scaleX(1);
  }
  90%{
      transform: translateY(0%) scaleY(0.95) scaleX(1);
  }
  100%{
      transform: translateY(0%) scaleY(1) scaleX(1);
  }
}

@-webkit-keyframes bounce {
  0% {
      -webkit-transform: translateY(0%) scaleY(0.6);
  }
  60%{
      -webkit-transform: translateY(-100%) scaleY(1.1);
  }
  70%{
      -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);
  }
  80%{
      -webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);
  }
  90%{
      -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);
  }
  100%{
      -webkit-transform: translateY(0%) scaleY(1) scaleX(1);
  }
}
